import { DataGrid } from '@mui/x-data-grid';
import { useSearchParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import QuickSearchToolbar from "../QuickSearchToolbar";
import { useNavigate, useLocation } from "react-router-dom";
import React, { useEffect } from "react";
import { Box, Fab, Paper } from "@mui/material";
import useRouting from "../../hooks/useRouting";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const FormTable = ({
    columns,
    columnVisibility,
    setColumnVisibility,
    data,
    onRowClick,
    onNewClick,
    paginationModel,
    setPaginationModel,
    onSearchTextChange,
    isLoading,
    createButtonLabel,
    sortModel,
    setSortModel,
    onScreenSizeChange,
    createPermissions,
    onExportClick,
    exportButtonLabel,
    exportPermissions,
    onImportClick,
    importButtonLabel,
    importPermissions,
}) => {
    const [isSmallScreen, setIsSmallScreen] = React.useState(window.innerWidth < 600);

    const [searchParams] = useSearchParams();
    const routing = useRouting();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [rows, setRows] = React.useState([]);
    const [total, setTotal] = React.useState(0);
    const [searchText, setSearchText] = React.useState(searchParams.get('search') ?? '');

    const getFilterParams = () => {
        let params = {};
        if (paginationModel.page !== 0)
            params['page'] = paginationModel.page;
        if (paginationModel.pageSize !== 10)
            params['pageSize'] = paginationModel.pageSize;
        if (searchText !== '')
            params['search'] = searchText;
        if (sortModel.length > 0) {
            params['sortField'] = sortModel[0].field;
            params['sortOrder'] = sortModel[0].sort;
        }
        return params;
    };

    const pushHistory = (key = null, value = null) => {
        let params = getFilterParams();

        if (value)
            params[key] = value;
        else if (key)
            delete params[key];

        navigate({
            pathname: pathname,
            search: "?" + new URLSearchParams(params).toString(),
        });
    };

    React.useEffect(() => {
        if (data?.data) {
            setRows(data.data);
        }
        if (data?.meta?.total) {
            setTotal(data.meta.total);
        }
    }, [data]);

    useEffect(() => {
        onSearchTextChange(searchText);
    }, [searchText]);

    useEffect(() => {
        // setPaginationModel(paginationModel);
        pushHistory();
    }, [paginationModel, sortModel]);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 600);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        onScreenSizeChange(isSmallScreen);
    }, [isSmallScreen]);

    const changePage = (steps) => {
        setPaginationModel({
            page: paginationModel.page + steps,
            pageSize: paginationModel.pageSize
        })
    }

    return (
        <div style={{ padding: 20, height: '100%', width: '100%', minHeight: 400 }}>
            <Paper elevation={0}>
                <DataGrid
                    paginationMode="server"
                    slots={{ toolbar: QuickSearchToolbar }}
                    rows={rows}
                    columns={columns}
                    pagination
                    rowCount={total}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    initialState={{
                        pagination: { paginationModel: { pageSize: 10 } },
                    }}
                    columnVisibilityModel={columnVisibility}
                    onColumnVisibilityModelChange={setColumnVisibility}
                    pageSizeOptions={[10, 25, 100]}
                    autoHeight={true}
                    onRowClick={onRowClick}
                    loading={isLoading}
                    hideFooterPagination={isSmallScreen}
                    sortingMode="server"
                    sortModel={sortModel}
                    onSortModelChange={setSortModel}
                    slotProps={{
                        toolbar: {
                            value: searchText,
                            onChange: (event) => setSearchText(event.target.value),
                            onBlur: (event) => pushHistory('search', event.target.value),
                            clearSearch: () => {
                                setSearchText('');
                                pushHistory('search', '');
                            },
                            onClickCreate: onNewClick,
                            createButtonLabel: createButtonLabel,
                            createPermissions: createPermissions,
                            onClickExport: onExportClick,
                            exportButtonLabel: exportButtonLabel,
                            exportPermissions: exportPermissions,
                            onClickImport: onImportClick,
                            importButtonLabel: importButtonLabel,
                            importPermissions: importPermissions,
                        },
                    }}
                    sx={{
                        '&.MuiDataGrid-root': {
                            border: '0 !important',
                        },
                        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                            outline: 'none !important',
                        },
                        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                            outline: 'none !important',
                        },
                        '& > .MuiDataGrid-columnSeparator': {
                            visibility: 'hidden',
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 'bold'
                        },
                        '& .data-grid-header': {
                            '& > .MuiDataGrid-columnSeparator': {
                                visibility: 'hidden',
                            },
                        },
                        '& .data-grid-header:first-of-type': {
                            paddingLeft: 4
                        },
                        '& .MuiDataGrid-cell:first-of-type': {
                            paddingLeft: 4
                        },
                        '& .data-grid-header:last-of-type': {
                            paddingRight: 4
                        },
                        '& .MuiDataGrid-cell:last-of-type': {
                            paddingRight: 4
                        },
                    }}
                />
            </Paper>
            {isSmallScreen &&
                <Box
                    sx={{
                        position: 'fixed',
                        bottom: 16,
                        '@media (min-width: 600px)': {
                            display: 'none',
                        },
                    }}
                >
                    <Fab
                        disabled={paginationModel.page === 0}
                        onClick={() => changePage(-1)}
                        size="small"
                        color="grey"
                        aria-label="left">
                        <ChevronLeftIcon />
                    </Fab>
                    <Fab
                        disabled={paginationModel.page >= data?.meta.last_page - 1}
                        onClick={() => changePage(1)}
                        sx={{
                            marginLeft: 1
                        }}
                        size="small"
                        color="grey"
                        aria-label="right">
                        <ChevronRightIcon />
                    </Fab>
                </Box>
            }
        </div>
    );
}

export default FormTable;
