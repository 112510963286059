const useConfig = () => {

    // const eventColors = {
    //     notPerformed: '#f56565',
    //     notPerformedLight: '#f6adad',
    //     performed: '#ed8936',
    //     performedLight: '#fbd38d',
    //     confirmed: '#9ae6b4',
    //     notAssigned: '#48bb78',
    //     internal: '#3788d8',
    // }

    const eventColors = {
        // light grey
        lightGrey: '#f3f4f6',
        mediumGrey: '#e5e7eb',
        darkGrey: '#6B7280',
        notPerformed: '#e5e7eb',
        notPerformedLight: '#e5e7eb',
        performed: '#48bb78',
        performedLight: '#48bb78',
        confirmed: '#9ae6b4',
        notAssigned: '#48bb78',
        internal: '#3788d8',
    }

    const headlineSx = {
        padding: 2,
    }

    const gridItemSxLabel = {
        padding: 2,
        fontSize: 14,
        fontWeight: 600,
        color: '#6B7280',
        '@media (max-width:600px)': {
            paddingBottom: 0
        }
    }

    const gridItemSxLabelMultiline = {
        height: '100%',
        paddingTop: 2,
        paddingBottom: 2,
        paddingLeft: 2,
        paddingRight: 2,
        fontSize: 14,
        fontWeight: 600,
        color: '#6B7280',
        '@media (max-width:600px)': {
            paddingBottom: 0
        }
    }

    const gridItemSxLabelMultilineEditable = {
        height: '100%',
        paddingTop: 1,
        paddingBottom: 1,
        paddingLeft: 2,
        paddingRight: 2,
        fontSize: 14,
        fontWeight: 600,
        color: '#6B7280',
        '@media (max-width:600px)': {
            paddingBottom: 0
        }
    }

    const gridItemSxValue = {
        paddingLeft: 2,
        paddingRight: 2,
        paddingTop: 2,
        paddingBottom: 2,
        fontSize: 14,
    }

    const gridItemSxValueEditable = {
        paddingLeft: 2,
        paddingRight: 2,
        paddingTop: 1,
        paddingBottom: 1,
        fontSize: 14,
    }

    const gridItemSxError = {
        paddingLeft: 2,
        paddingRight: 2,
        paddingTop: 0,
        paddingBottom: 2,
        fontSize: 14

    }

    return {
        eventColors,
        headlineSx,
        gridItemSxLabel,
        gridItemSxLabelMultiline,
        gridItemSxLabelMultilineEditable,
        gridItemSxValue,
        gridItemSxValueEditable,
        gridItemSxError
    }
}

export default useConfig;
