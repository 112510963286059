import {
    Box, Breadcrumbs,
    Button,
    CircularProgress,
    Grid,
    MenuItem,
    Link, Paper,
    Stack,
    TextField, Typography
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import useApi from "../../hooks/useApi";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import FormSelect from "../forms/FormSelect";
import useRouting from "../../hooks/useRouting";
import "./InternalEventType.css";

const InternalEventType = () => {

    const { t } = useTranslation();
    const { id } = useParams();

    const { queryInternalEventType, queryInternalEventTypes, saveOrUpdateInternalEventType } = useApi();
    const { goToInternalEventType, goToInternalEventTypes } = useRouting();
    const { data: internalEventType = null, isLoading, refetch } = queryInternalEventType(id);

    const [editable, setEditable] = useState(id === 'new');
    const [disabled, setDisabled] = useState(false);
    const [type, setType] = useState('');
    const [description, setDescription] = useState('');
    const [active, setActive] = useState(1);
    const [errType, setErrType] = useState(false);
    const [errDescription, setErrDescription] = useState(false);
    const [showValidation, setShowValidation] = useState(false);
    const navigate = useNavigate();

    const labelSx = {
        padding: 2,
        fontSize: 14,
        fontWeight: 600,
        color: '#6B7280',
        '@media (max-width:600px)': {
            paddingBottom: 0
        }
    }

    const valueSx = {
        paddingLeft: 2,
        paddingRight: 2,
        paddingTop: editable ? 1 : 2,
        paddingBottom: editable ? 1 : 2,
        fontSize: 14,
    }

    const init = () => {
        setType(internalEventType.type);
        setDescription(internalEventType.description);
        setActive(internalEventType?.active);
    }

    useEffect(() => {
        if (internalEventType && internalEventType.id) {
            init();
        }
    }, [internalEventType]);

    const handleGoingBack = () => {
        if (window.history.state && window.history.state.idx > 0) {
            navigate(-1);
        } else {
            goToInternalEventTypes();
        }
    }

    const handleTypeChange = (event) => {
        setType(event.target.value);
        setErrType(false);
    }

    const handleDescriptionChange = (event) => {
        setDescription(event.target.value);
        setErrDescription(false);
    }

    const handleActiveChange = (event) => {
        setActive(event.target.value);
        setErrActive(false);
    }

    const handleCancel = () => {
        setEditable(false);
        if (internalEventType && internalEventType.id) {
            init();
        } else {
            goToInternalEventTypes();
            setType('');
            setDescription('');
            setActive(0);
        }
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const validate = () => {
        let valid = true;

        if (type === "") {
            setErrType(true);
            valid = false;
        } else {
            setErrType(false);
        }

        return valid;

    }

    const handleSave = () => {
        setDisabled(true);

        if (validate()) {
            let data = {};
            if (id) {
                data.id = id;
            }

            data.type = type;
            data.description = description;
            data.active = active

            saveOrUpdateInternalEventType(data).then((response) => {

                setDisabled(false);
                setEditable(false);
                if (data.id === 'new' && response.data.id) {
                    goToInternalEventType(response.data.id);
                } else {
                    refetch();
                }
            }).catch((error) => {
                setDisabled(false);
                if (error.response?.status === 422) {
                    let errors = error.response.data.errors;
                    if (errors.shortname) {
                        setErrShortname(true);
                    }

                    if (errors.type) {
                        setErrType(true);
                    }
                }

            });
        } else {
            setDisabled(false);
        }
    };

    return (
        <Box sx={{ padding: 2 }}>
            {isLoading ?
                <Stack sx={{ flexGrow: 1, padding: 2 }} alignItems="center">
                    <CircularProgress />
                </Stack>
                :
                <>
                    <Breadcrumbs aria-label="breadcrumb" sx={{ marginBottom: 2 }}>
                        <NavLink underline="hover" color="inherit" to="/internal-event-types">
                            <Trans i18nKey="internal-event-types.title">InternalEventTypes</Trans>
                        </NavLink>
                        <Typography color="text.primary">{internalEventType?.type}</Typography>
                    </Breadcrumbs>
                    <Paper elevation={0} sx={{ marginBottom: 2, padding: 2, maxWidth: 1024 }}>
                        <Grid container alignItems="center">
                            <Grid item xs={8}>
                                <Box sx={{ paddingLeft: 2, display: 'flex', justifyContent: 'flex-start' }}>
                                    {editable && internalEventType?.id &&
                                        <Typography variant="h5"><Trans i18nKey={"internal-event-types.edit-title"}>Edit
                                            internalEventType</Trans></Typography>
                                    }
                                    {editable && !internalEventType?.id &&
                                        <Typography variant="h5"><Trans
                                            i18nKey={"internal-event-types.create-title"}>Create
                                            internalEventType</Trans></Typography>
                                    }
                                    {!editable &&
                                        <Typography variant="h5">{internalEventType?.type}</Typography>
                                    }

                                </Box>
                            </Grid>
                            <Grid item xs={4} justifyContent="flex-end">
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    {!editable &&
                                        <Button
                                            sx={{ textTransform: 'none' }}
                                            variant="outlined"
                                            size="small"
                                            disableElevation
                                            onClick={() => setEditable(!editable)}
                                        >
                                            <Trans i18nKey="internal-event-types.edit">Edit</Trans>
                                            {/*<EditOutlinedIcon />*/}
                                        </Button>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                    <Paper elevation={0} sx={{ padding: 2, maxWidth: 1024 }}>
                        <Grid container alignItems="center">

                            <Grid item xs={12} sm={4} sx={labelSx}>
                                <Trans i18nKey={"internal-event-types.type.label"}>Type</Trans>
                            </Grid>

                            <Grid item xs={12} sm={8} sx={valueSx}>
                                {editable ?
                                    <TextField
                                        disabled={disabled}
                                        id="type"
                                        fullWidth
                                        /*multiline*/
                                        hiddenLabel
                                        size="small"
                                        variant="outlined"
                                        value={type}
                                        placeholder={t('internal-event-types.type.placeholder', 'Enter type')}
                                        InputProps={{
                                            style: {
                                                fontSize: 14, border: 'none',
                                                outline: 'none',
                                            }
                                        }}
                                        sx={{ fontSize: 14, backgroundColor: '#fff' }}
                                        onChange={handleTypeChange}
                                        error={errType}
                                    />
                                    :
                                    internalEventType?.type
                                }
                                {errType && (
                                    <p className="mt-2 text-sm text-red-600">
                                        <Trans i18nKey="internal-event-types.type.error">
                                                Please enter an internal event type.
                                        </Trans>
                                    </p>
                                )}
                            </Grid>


                            <Grid item xs={12} sm={4} sx={labelSx}>
                                <Trans i18nKey="internal-event-types.description.label">Description</Trans>
                            </Grid>
                            <Grid item xs={12} sm={8} sx={valueSx}>
                                {editable ?
                                    <TextField
                                        disabled={disabled}
                                        id="description"
                                        fullWidth
                                        /*multiline*/
                                        hiddenLabel
                                        size="small"
                                        variant="outlined"
                                        value={description}
                                        placeholder={t('internal-event-types.description.placeholder', 'Enter description')}
                                        InputProps={{
                                            style: {
                                                fontSize: 14, border: 'none',
                                                outline: 'none',
                                            }
                                        }}
                                        sx={{ fontSize: 14, backgroundColor: '#fff' }}
                                        onChange={handleDescriptionChange}
                                        error={errDescription}
                                    />
                                    :
                                    internalEventType?.description
                                }
                                {errDescription && (
                                    <p className="mt-2 text-sm text-red-600">
                                        <Trans i18nKey="internal-event-types.description.error">
                                                Please enter a internal event type description.
                                        </Trans>
                                    </p>
                                )}
                            </Grid>

                            <FormSelect
                                i18nKeyLabel="internal-event-types.active.label"
                                editable={editable}
                                valueText={t('internal-event-types.active.' + active)}
                                value={active}
                                onChange={handleActiveChange}
                                disabled={disabled}
                                showValidation={showValidation}
                                required={false}
                                displayEmpty={true}>
                                <MenuItem sx={{ fontSize: 14 }} value={1}><Trans
                                    i18nKey="internal-event-types.active.1"></Trans></MenuItem>
                                <MenuItem sx={{ fontSize: 14 }} value={0}><Trans
                                    i18nKey="internal-event-types.active.0"></Trans></MenuItem>

                            </FormSelect>


                        </Grid>

                        {editable && (
                            <Grid container maxWidth="1024px">
                                <Grid item xs={12}>
                                    <Box sx={{ padding: 2, display: 'flex', justifyContent: 'flex-end' }}>
                                        <Button
                                            variant="outlined"
                                            size="small"
                                            sx={{
                                                marginRight: 2,
                                                textTransform: 'none', background: '#fff'
                                            }}
                                            onClick={handleCancel}
                                        >
                                            <Trans i18nKey="internal-event-types.cancel">Cancel</Trans>
                                        </Button>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            size="small"
                                            disableElevation
                                            sx={{ textTransform: 'none' }}
                                            onClick={handleSave}
                                        >
                                            <Trans i18nKey="internal-event-types.save">Save</Trans>
                                        </Button>

                                    </Box>
                                </Grid>
                            </Grid>
                        )}
                    </Paper>
                </>
            }
        </Box>
    )
}

export default InternalEventType;
