import {Autocomplete, Grid, TextField, Typography} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import useConfig from "../../hooks/useConfig";
import {useTheme} from "@mui/material/styles";

const FormAutocomplete = ({i18nKeyLabel,
                            i18nKeyPlaceholder,
                            valueText,
                            editable,
                            options,
                            getOptionLabel,
                            value,
                            onChange,
                            disabled,
                            error,
                            i18nErrorKey,
                            i18nErrorValue,
                            showValidation,
                            required,
                            multiple = false}) => {
    const theme = useTheme();
    const {t} = useTranslation();
    const {gridItemSxLabel, gridItemSxValue, gridItemSxValueEditable, gridItemSxError} = useConfig();

    return (
        <>
            <Grid item xs={12} sm={4} sx={gridItemSxLabel}>
                <Trans i18nKey={i18nKeyLabel}>{i18nKeyLabel}</Trans>
                {editable && required && <Typography component='span' sx={{color: theme.palette.error.main}}>*</Typography>}
            </Grid>
            <Grid item xs={12} sm={8} sx={editable ? gridItemSxValueEditable : gridItemSxValue}>
                { editable ?
                    <Autocomplete
                        noOptionsText={t('forms.no-options', 'No options')}
                        multiple={multiple}
                        size="small"
                        disabled={disabled}
                        value={value}
                        onChange={(event, newValue) => {
                            onChange(newValue);
                        }}
                        options={options ?? []}
                        getOptionLabel={(option) => getOptionLabel(option)}
                        renderOption={(props, option) => {
                            return (
                                <li {...props} key={'form-autocomplete-option-' + option.id}>
                                    {getOptionLabel(option)}
                                </li>
                            );
                        }}
                        isOptionEqualToValue={(option, value) => option.id === value?.id}
                        filterSelectedOptions
                        sx={{
                            fontSize: '14px !important',
                            '& input': {
                                fontSize: 14,
                            },
                            '& .MuiAutocomplete-listbox': {
                                fontSize: '14px !important',
                            }
                        }}
                        ListboxProps={{
                            sx: {fontSize: 14},
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                placeholder={t(i18nKeyPlaceholder, 'Select an option')}
                                error={showValidation && error}
                            />
                        )}
                    />
                    :
                    valueText ?? '-'
                }
            </Grid>
            {error && (
                <>
                <Grid item xs={12} sm={4} />
                <Grid item xs={12} sm={8} sx={gridItemSxError}>
                <p className="text-sm text-red-600">
                    <Trans i18nKey={i18nErrorKey}>
                            {i18nErrorValue}
                    </Trans>
                </p>
                </Grid>
            </>
            )}
            </>
    );
}

export default FormAutocomplete;
