import useApi from "../../hooks/useApi";
import {useSearchParams} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";
import {useNavigate, useLocation} from "react-router-dom";
import React, {useEffect} from "react";
import FormTable from "./../forms/FormTable";
import useUser from "../../hooks/useUser";
import ImportModal from "../ImportModal";

const Employees = (props) => {
    const [searchParams] = useSearchParams();
    const {getPermissions} = useUser();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {queryEmployees, exportEmployees, convertEmployees, importEmployees} = useApi();
    const [columns, setColumns] = React.useState([
        {
            field: 'name',
            headerName: 'Name',
            editable: false,
            flex: 1,
            headerClassName: 'data-grid-header',
            renderCell: (params) => {
                const fullName = params.row.person ? params.row.person.last_name +', '+ params.row.person.first_name : '';
                return <b>{fullName}</b>;
            },
        },
        {
            field: 'team',
            headerName: 'Team',
            editable: false,
            flex: 1,
            headerClassName: 'data-grid-header',
            renderCell: (params) => {
                const teams = params.row.teams ? params.row.teams.map(team => team.name).join('<br>') : '';
                return <div dangerouslySetInnerHTML={{ __html: teams }} />;
            },
        },
        {
            field: 'email',
            headerName: t('employees.company-address-email','Company email address (login)'),
            editable: false,
            flex: 1,
            headerClassName: 'data-grid-header',
            renderCell: (params) => {
                const email = params.row.email ?? '';
                return email;
            },
        },
        {
            field: 'phone',
            headerName: t('employees.phone','Phone'),
            editable: false,
            flex: 1,
            headerClassName: 'data-grid-header',
            renderCell: (params) => {
                const phone = params.row.phone; // Assuming phone is an object
                const phoneNumber = phone?.phone_number ?? '';
                return phoneNumber;
            },
        },
        {
            field: 'roles',
            headerName: t('employees.roles.label','Roles'),
            editable: false,
            flex: 1,
            sortable: false,
            headerClassName: 'data-grid-header',
            renderCell: (params) => {
                const roles = params.row.roles ? params.row.roles.map(role => t(`employees.role.${role.name}`, role.name)).join('<br>') : '';
                return <div dangerouslySetInnerHTML={{ __html: roles }} />;
            },
        },
    ]);

    const [isImportModalOpen, setIsImportModalOpen] = React.useState(false);

    const isSmallScreen = window.innerWidth < 600;
    const [columnVisibility, setColumnVisibility] = React.useState({
        roles: !isSmallScreen,
        team: !isSmallScreen,
        phone: !isSmallScreen,
    });
    const [searchText, setSearchText] = React.useState(searchParams.get('search') ?? '');
    const [sortModel, setSortModel] = React.useState(searchParams.get('sortField') && searchParams.get('sortOrder') ? [{
        field: searchParams.get('sortField'),
        sort: searchParams.get('sortOrder'),
    }] : []);
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: searchParams.get('pageSize') ? parseInt(searchParams.get('pageSize')) : props.paginationModel.pageSize,
        page: searchParams.get('page') ? parseInt(searchParams.get('page')) : props.paginationModel.page,
    });
    const {data: employees = null, isLoading, refetch: employeesRefetch} = queryEmployees(
        paginationModel.pageSize,
        paginationModel.page + 1,
        sortModel.length > 0 ? sortModel[0].field : null,
        sortModel.length > 0 ? sortModel[0].sort : null,
        searchText);

    const handleRowClick = (params, event) => {
        navigate(`/employees/${params.id}`);
    }

    const handleNewEmployeesClick = (event) => {
        navigate(`/employees/new`);
    }
    
    const handleExportEmployeesClick = (event) => {
        exportEmployees();
    }

    const handleImportEmployeesClick = (event) => {
        setIsImportModalOpen(true);
    }

    
    const handleEmployeesConvert = (file, onFinish) => {
        convertEmployees(file, onFinish);
    }


    const handleEmployeesImport = (file, onFinish, onSuccess) => {
        importEmployees(file, onFinish, onSuccess, employeesRefetch);
    }


    const handleScreenSizeChange = (isSmallScreen) => {
        let newColumnVisibility = {
            ...columnVisibility,
        }
        newColumnVisibility.roles = !isSmallScreen;
        newColumnVisibility.team = !isSmallScreen;
        newColumnVisibility.phone = !isSmallScreen;
        setColumnVisibility(newColumnVisibility);
    }

    return (
        <>
            <FormTable
                createButtonLabel={t("employees.add-employee", "New Employee")}
                columns={columns}
                data={employees}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                onSearchTextChange={setSearchText}
                onRowClick={handleRowClick}
                onNewClick={handleNewEmployeesClick}
                sortModel={sortModel}
                setSortModel={setSortModel}
                isLoading={isLoading}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                createPermissions={["employees.create", "employees"]}
                onExportClick={handleExportEmployeesClick}
                exportButtonLabel={t("employees.export-employees", "Export Employees")}
                exportPermissions={["employees.export", "employees"]}
                onImportClick={handleImportEmployeesClick}
                importButtonLabel={t("employees.import-employees", "Import Employees")}
                importPermissions={["employees.import", "employees"]}
                onScreenSizeChange={handleScreenSizeChange}
            />
            <ImportModal 
                isOpen={isImportModalOpen}
                onClose={() => setIsImportModalOpen(false)}
                title={t("employees.import-employees", "Import Employees")}
                handleConvert={handleEmployeesConvert}
                handleImport={handleEmployeesImport}
            />
        </>
    );
}

export default Employees;
