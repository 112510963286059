import {
    Autocomplete,
    Box,
    Breadcrumbs,
    Button,
    CircularProgress, FormControl,
    Grid, InputAdornment,
    MenuItem, OutlinedInput,
    Paper,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import useApi from "../../hooks/useApi";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import React, {useEffect, useState} from "react";
import useRouting from "../../hooks/useRouting";
import FormSection from "../forms/FormSection";
import FormTextField from "../forms/FormTextField";
import FormSelect from "../forms/FormSelect";
import FormAutocomplete from "../forms/FormAutocomplete";
import FormDateField from "../forms/FormDateField";
import {DateTime} from "luxon";
import {useTab} from "@mui/base";


const ClientBudget = (props) => {

    const {t} = useTranslation();
    const {id} = useParams();
    const {budgetId} = useParams();
    const routing = useRouting();
    
    const permissionsMeta = document.querySelector('meta[name="permissions"]');
    const permissions = permissionsMeta ? permissionsMeta.content.split(',') : [];
    const canUpdateBudgets = permissions.includes('client.budgets.update') || permissions.includes('clients');

    const {queryClient, queryBudgets, saveOrUpdateClientBudget, queryClientBudget} = useApi();
    const {goToClientBudgets, goToClientBudget} = useRouting();

    const {data: client = null} = queryClient(id);
    const {data: budgets = null, isLoadingBudgets} = queryBudgets();
    const {data: clientBudget = null, isLoading, refetch} = queryClientBudget(id, budgetId);

    const [editable, setEditable] = useState(budgetId === 'new');

    const [disabled, setDisabled] = useState(false);
    const [selectedBudget, setSelectedBudget] = useState(null);
    const [validFromDate, setValidFromDate] = useState(null);
    const [validUntilDate, setValidUntilDate] = useState(null);
    const [numberofHours, setNumberofHours] = useState(0);
    const [hourlyRate, setHourlyRate] = useState(0)
    const [active, setActive] = useState(1);

    const [errSelectedBudget, setErrSelectedBudget] = useState(false);
    const [errValidFromDate, setErrValidFromDate] = useState(false);
    const [errValidUntilDate, setErrValidUntilDate] = useState(false);
    const [errNumberofHours, setErrNumberofHours] = useState(false);
    const [showValidation, setShowValidation] = useState(false);

    const labelSx = {
        padding: 2,
        fontSize: 14,
        fontWeight: 600,
        color: '#6B7280',
        '@media (max-width:600px)': {
            paddingBottom: 0
        }
    }

    const labelMultilineSx = {
        paddingTop: editable ? 1 : 2,
        paddingBottom: editable ? 1 : 2,
        paddingLeft: 2,
        paddingRight: 2,
        fontSize: 14,
        fontWeight: 600,
        color: '#6B7280',
        '@media (max-width:600px)': {
            paddingBottom: 0
        }
    }

    const valueSx = {
        paddingLeft: 2,
        paddingRight: 2,
        paddingTop: editable ? 1 : 2,
        paddingBottom: editable ? 1 : 2,
        fontSize: 14,
    }

    const init = () => {
        setSelectedBudget(clientBudget?.budget);
        if (clientBudget?.valid_from) {
            setValidFromDate(DateTime.fromFormat(clientBudget?.valid_from, 'yyyy-MM-dd'));
        } else {
            setValidFromDate(null);
        }
        if (clientBudget?.valid_until) {
            setValidUntilDate(DateTime.fromFormat(clientBudget?.valid_until, 'yyyy-MM-dd'));
        } else {
            setValidUntilDate(null);
        }
        if (clientBudget?.value) {
            setNumberofHours(clientBudget?.value);
        } else {
            setNumberofHours(0);
        }
        if (clientBudget?.rate) {
            setHourlyRate(clientBudget?.rate);
        } else {
            setHourlyRate(0);
        }
        setActive(clientBudget?.active);
    }

    useEffect(() => {
        if (clientBudget && clientBudget?.id) {
            init();
        }
    }, [clientBudget]);


    const handleBudgetChange = (newValue) => {
        setSelectedBudget(newValue);
        setErrSelectedBudget(false);
    };

    const handleValidFromDateChange = (date) => {
        setValidFromDate(date);
        setErrValidFromDate(false);
    }
    const handleValidUntilDateChange = (date) => {
        setValidUntilDate(date);
        setErrValidUntilDate(false);
    }

    const handleNumbersOfHoursChange = (event) => {
        setNumberofHours(event.target.value);
        setErrNumberofHours(false);
    }

    const handleActiveChange = (event) => {
        setActive(event.target.value);
    }

    const handleCancel = () => {
        setEditable(false);
        if (clientBudget && clientBudget?.id) {
            init();
        } else {
            goToClientBudgets(id);
        }
    };

    const handleHourlyRateChange = (event) => {
        setHourlyRate(event.target.value);
    };
    const handleHourlyRateBlur = () => {
        setHourlyRate(formatCurrency(hourlyRate));
    };

    const formatCurrency = (value) => {
        const formattedValue = new Intl.NumberFormat('de-DE', {
            style: 'currency',
            currency: 'EUR',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(value);
        // Move the Euro sign to the end
        return formattedValue.replace(/€/, '') + '€';
    };

    const validate = () => {
        let valid = true;

        if (selectedBudget === null) {
            setErrSelectedBudget(true);
            valid = false;
        } else {
            setErrSelectedBudget(false);
        }
        if (validFromDate === null) {
            setErrValidFromDate(true);
            valid = false;
        } else {
            setErrValidFromDate(false);
        }

        if (validUntilDate !== null && validUntilDate < validFromDate) {
            setErrValidUntilDate(true);
            valid = false;
        } else {
            setErrValidUntilDate(false);
        }
        return valid;

    }
    const handleSave = () => {
        setDisabled(true);

        if (validate()) {
            let data = {};
            if (budgetId && budgetId !== "new") {
                data.id = budgetId;
            }
            data.client_id = id;
            data.budget_id = selectedBudget.id;
            data.active = active;
            if (validFromDate) {
                data.valid_from = validFromDate.toFormat('yyyy-MM-dd');
            }
            if (validUntilDate) {
                data.valid_until = validUntilDate.toFormat('yyyy-MM-dd');
            }
            data.value = numberofHours;
            data.rate = hourlyRate;

            saveOrUpdateClientBudget(id, data).then((response) => {
                setDisabled(false);
                setEditable(false);
                if (response.data.id) {
                    refetch();
                    goToClientBudget(id, response.data.id);
                } else {
                    refetch();
                }
            }).catch((error) => {
                setDisabled(false);
                if (error.response?.status === 422) {
                    let errors = error.response.data.errors;
                    if (errors.valid_from) {
                        setErrValidFromDate(true);
                    }
                    if (errors.budget_id) {
                        setErrSelectedBudget(true);
                    }
                    if (errors.valid_until) {
                        setErrValidUntilDate(true);
                    }

                }

            });
        } else {
            setShowValidation(true);
            setDisabled(false);
            console.log('validation error');
        }
    };

    return (
        <Box sx={{padding: 2}}>
            {isLoading || isLoadingBudgets ?
                <Stack sx={{flexGrow: 1, padding: 2}} alignItems="center">
                    <CircularProgress/>
                </Stack>
                :
                <>
                    <Breadcrumbs aria-label="breadcrumb" sx={{marginBottom: 2}}>
                        <NavLink underline="hover" color="inherit" to="/clients">
                            <Trans i18nKey="clients.header">Clients</Trans>
                        </NavLink>
                        <NavLink color="text.primary" underline="hover" to={routing.getClientPath(id)}>
                            <Typography
                                color="text.primary">{client?.person.last_name}, {client?.person.first_name}</Typography></NavLink>
                        <NavLink underline="hover" color="inherit"
                                 to={`/clients/${id}/budgets`}>
                            <Trans i18nKey="clients.budgets.header">Budgets</Trans>
                        </NavLink>
                        {clientBudget?.id ?
                            <Typography
                                color="text.primary">{clientBudget?.budget ? clientBudget.budget.name : ""}</Typography>
                            :
                            <Trans i18nKey="clients.budgets.new-budget">New Budget</Trans>
                        }
                    </Breadcrumbs>
                    <Paper elevation={0} sx={{marginBottom: 2, padding: 2, maxWidth: 1024}}>
                        <Grid container alignItems="center">
                            <Grid item xs={8}>
                                <Box sx={{paddingLeft: 2, display: 'flex', justifyContent: 'flex-start'}}>
                                    {editable && clientBudget?.id && 
                                        <Typography variant="h5"><Trans i18nKey={"clients.budgets.edit-title"}>Edit
                                            Budget</Trans></Typography>
                                    }
                                    {editable && !clientBudget.id &&
                                        <Typography variant="h5"><Trans
                                            i18nKey={"clients.budgets.create-new-budget"}>Create new Budget
                                        </Trans></Typography>
                                    }
                                    {!editable &&
                                        <Typography
                                            variant="h5">{clientBudget?.budget ? clientBudget.budget.name : ""}</Typography>
                                    }

                                </Box>
                            </Grid>
                            <Grid item xs={4} justifyContent="flex-end">
                                <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                    {!editable && canUpdateBudgets &&
                                        <Button
                                            sx={{textTransform: 'none'}}
                                            variant="outlined"
                                            size="small"
                                            disableElevation
                                            onClick={() => {
                                                setEditable(!editable)
                                            }}
                                        >
                                            <Trans i18nKey="employee.edit">Edit</Trans>
                                        </Button>
                                    }
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>

                    <FormSection
                        editable={editable}
                        showButtons={true}
                        onSave={handleSave}
                        onCancel={handleCancel}
                    >
                        <FormAutocomplete
                            value={selectedBudget}
                            editable={editable}
                            onChange={handleBudgetChange}
                            i18nKeyLabel="clients.budgets.label"
                            i18nKeyPlaceholder="clients.budgets.placeholder"
                            options={budgets}
                            getOptionLabel={(option) => option.name}
                            valueText={selectedBudget?.name ?? '-'}
                            disabled={disabled}
                            showValidation={showValidation}
                            error={showValidation && errSelectedBudget}
                        />

                        <FormTextField
                            value={selectedBudget ? selectedBudget.description : '-'}
                            i18nKeyLabel="clients.budgets.description"
                            valueText={selectedBudget ? selectedBudget.description : '-'}
                            disabled={disabled}
                            showValidation={showValidation}
                        />

                        <FormTextField
                            value={selectedBudget ? selectedBudget.legal_basis : '-'}
                            i18nKeyLabel="clients.budgets.legal_basis"
                            valueText={selectedBudget ? selectedBudget.legal_basis : '-'}
                            disabled={disabled}
                            showValidation={showValidation}
                        />

                        <FormDateField
                            i18nKeyLabel="clients.budgets.valid_from.label"
                            i18nKeyPlaceholder="clients.budgets.valid_from.placeholder"
                            i18nKeyDateFormat="clients.birthdate.date"
                            editable={editable}
                            valueText={validFromDate}
                            value={validFromDate}
                            onChange={handleValidFromDateChange}
                            disabled={disabled}
                            showValidation={showValidation}
                            error={errValidFromDate}
                        />

                        <FormDateField
                            i18nKeyLabel="clients.budgets.valid_until.label"
                            i18nKeyPlaceholder="clients.budgets.valid_until.placeholder"
                            i18nKeyDateFormat="clients.birthdate.date"
                            editable={editable}
                            valueText={validUntilDate}
                            value={validUntilDate}
                            onChange={handleValidUntilDateChange}
                            disabled={disabled}
                            showValidation={showValidation}
                            error={errValidUntilDate}
                        />

                        <Grid item xs={12} sm={4} sx={labelSx}>
                            <Trans i18nKey="clients.budgets.hours-number">hours per week</Trans>
                        </Grid>
                        <Grid item xs={12} sm={8} sx={valueSx}>
                            {editable ?
                                <TextField
                                    type="number"
                                    value={numberofHours}
                                    hiddenLabel
                                    size="small"
                                    onChange={handleNumbersOfHoursChange}
                                    InputProps={{style: {fontSize: 14}}}
                                    sx={{fontSize: 14, backgroundColor: '#fff', width: 210}}
                                    error={errNumberofHours}
                                />
                                :
                                clientBudget?.value ? clientBudget.value + ' h' : 0 + ' h'
                            }
                        </Grid>

                        <Grid item xs={12} sm={4} sx={labelSx}>
                            <Trans i18nKey="clients.budgets.hourly-rate">Hourly Rate</Trans>
                        </Grid>
                        <Grid item xs={12} sm={8} sx={valueSx}>
                            {editable ?
                                <TextField
                                    type="text"
                                    value={hourlyRate}
                                    hiddenLabel
                                    size="small"
                                    onChange={handleHourlyRateChange}
                                    //onBlur={handleHourlyRateBlur}
                                    InputProps={{style: {fontSize: 14}}}
                                    sx={{fontSize: 14, backgroundColor: '#fff', minWidth: 210}}

                                />
                                :
                                formatCurrency(clientBudget?.rate ? clientBudget?.rate : 0)
                            }
                        </Grid>

                        <FormTextField
                            // value={hourlyRate * numberofHours}
                            i18nKeyLabel="clients.budgets.current_amount"
                            valueText={
                                <b>{formatCurrency(hourlyRate?.toString().replace(',', '.') * numberofHours)}</b>}
                            disabled={disabled}
                            showValidation={showValidation}
                        />

                        <FormSelect
                            i18nKeyLabel="clients.budgets.active.label"
                            editable={editable}
                            valueText={t('clients.budgets.active.' + active)}
                            value={active}
                            onChange={handleActiveChange}
                            disabled={disabled}
                            showValidation={showValidation}
                            required={false}
                            displayEmpty={true}>
                            <MenuItem sx={{fontSize: 14}} value={1}><Trans
                                i18nKey="clients.budgets.active.1"></Trans></MenuItem>
                            <MenuItem sx={{fontSize: 14}} value={0}><Trans
                                i18nKey="clients.budgets.active.0"></Trans></MenuItem>

                        </FormSelect>

                    </FormSection>

                </>
            }
        </Box>
    );
}

export default ClientBudget;
