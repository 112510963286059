import React, { Fragment, useEffect, useRef, useState } from "react";
import { Menu, Transition } from "@headlessui/react";

import { PlusIcon, TrashIcon } from "@heroicons/react/20/solid";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import useApi from "../hooks/useApi";
import { Trans } from "react-i18next";

const EventDropdown = ({ eventHandler }) => {
    const [isOpen, setIsOpen] = useState(false);
    const buttonRef = useRef(null);
    const menuRef = useRef(null);

    const handleClickOutside = (event) => {
        if (buttonRef.current && !buttonRef.current.contains(event.target) &&
            menuRef.current && !menuRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    const handleDropdownButtonClick = (event) => {
        event.stopPropagation();
        setIsOpen(!isOpen);
    };

    const handleEventButtonClick = (event, eventType) => {
        event.preventDefault();
        eventHandler(eventType);
        setIsOpen(false);
    }

    useEffect(() => {
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <Menu as="div" className="relative inline-block text-left" style={{ zIndex: 8 }}>
            {({ open }) => (
                <>
                    <div ref={buttonRef}>
                        <Menu.Button
                            className="inline-flex justify-center items-center w-full pl-4 py-0 text-sm font-medium text-white bg-blue-500 rounded-md border border-transparent hover:bg-blue-600 focus:outline-none"
                            onClick={(event) => handleEventButtonClick(event, 'client_appointment')}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5"
                                stroke="currentColor" className="md:hidden w-5 h-5">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                            </svg>

                            <div className="hidden md:block whitespace-nowrap"><Trans i18nKey="team-calendar.new-event">New event</Trans></div>
                            <div
                                className="flex items-center ml-2 -mr-1 h-9 w-9"
                                onClick={handleDropdownButtonClick}
                            >
                                <ChevronDownIcon className="h-5 w-5" />
                            </div>
                        </Menu.Button>
                    </div>

                    <Transition
                        show={isOpen}
                        enter="transition ease-out duration-100 transform"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="transition ease-in duration-75 transform"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Menu.Items
                            ref={menuRef}
                            static
                            className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
                        >
                            <div className="py-1">
                                <Menu.Item key='client-appointment'>
                                    <a
                                        href="#"
                                        className={"text-gray-700 flex items-center px-4 py-2 text-sm"}
                                        onClick={(event) => handleEventButtonClick(event, 'client_appointment')}
                                    >
                                        <Trans i18nKey={"team-calendar.event-types.client_appointment"}>Client appointment</Trans>
                                    </a>
                                </Menu.Item>
                                <Menu.Item key='internal'>
                                    <a
                                        href="#"
                                        className={"text-gray-700 flex items-center px-4 py-2 text-sm"}
                                        onClick={(event) => handleEventButtonClick(event, 'internal')}
                                    >
                                        <Trans i18nKey={"team-calendar.event-types.internal"}>Internal</Trans>
                                    </a>
                                </Menu.Item>
                                <Menu.Item key='holiday'>
                                    <a
                                        href="#"
                                        className={"text-gray-700 flex items-center px-4 py-2 text-sm"}
                                        onClick={(event) => handleEventButtonClick(event, 'holiday')}
                                    >
                                        <Trans i18nKey={"team-calendar.event-types.holiday"}>Holiday</Trans>
                                    </a>
                                </Menu.Item>
                                <Menu.Item key='sick'>
                                    <a
                                        href="#"
                                        className={"text-gray-700 flex items-center px-4 py-2 text-sm"}
                                        onClick={(event) => handleEventButtonClick(event, 'sick')}
                                    >
                                        <Trans i18nKey={"team-calendar.event-types.sick"}>Sick</Trans>
                                    </a>
                                </Menu.Item>
                            </div>
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>
    );
};

export default EventDropdown;
