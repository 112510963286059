import React, {useEffect, useRef, useState} from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid';
import rrulePlugin from '@fullcalendar/rrule'
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import deLocale from '@fullcalendar/core/locales/de';
import useApi from "../hooks/useApi";
import useConfig from "../hooks/useConfig";
import TeamMemberDropdown from "./TeamMemberDropdown";
import {Trans, useTranslation} from "react-i18next";
import EventModal from "./EventModal";
import moment from "moment";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import useRouting from "../hooks/useRouting"
import EventDropdown from "./EventDropdown";
import TeamCalendarNavigation from "./TeamCalendarNavigation";
import "./TeamCalendar.css";
import EventsExportModal from "./EventsExportModal";
import ImportModal from "./ImportModal";
import useUser from "../hooks/useUser";
import useTeamCalendarEventUpdater from "./events/TeamCalendarEventUpdater";

export default function TeamCalendar() {
    const {t} = useTranslation();
    const {goToTeamCalendar, goToTeamCalendarNewEvent, goToTeamCalendarEvent} = useRouting();
    const {getEmployeeId} = useUser();
    const employeeId = getEmployeeId();
    const userIdMeta = document.querySelector('meta[name="user_id"]');
    const userId = userIdMeta ? parseInt(userIdMeta.content) : null;
    const teamMeta = document.querySelector('meta[name="team_id"]');
    const teamId = teamMeta ? teamMeta.content : null;
    const permissionsMeta = document.querySelector('meta[name="permissions"]');
    const permissions = permissionsMeta ? permissionsMeta.content.split(',') : [];
    const canTeamEvents = permissions.includes('team.events') || permissions.includes('teams');
    const canExportEvents = permissions.includes('events.export');
    const canImportEvents = permissions.includes('events.import');
    const calendarRef = useRef(null);
    const [initialView, setInitialView] = React.useState('timeGridWeek');
    const [hiddenDays, setHiddenDays] = React.useState(moment().isoWeekday() === 6 || moment().isoWeekday() ? [] : [0, 6]);
    const [range, setRange] = React.useState({start: null, end: null});
    const [teamSelection, setTeamSelection] = React.useState([]);
    const [filteredEvents, setFilteredEvents] = React.useState([]);
    const [isEventsExportModalOpen, setIsEventsExportModalOpen] = React.useState(false);
    const [isEventsImportModalOpen, setIsEventsImportModalOpen] = React.useState(false);
    const [selectedEvent, setSelectedEvent] = React.useState(null);
    const [selectedDate, setSelectedDate] = React.useState(null);
    const {queryTeamEvents, queryEvents, queryPrimaryPatientsIds, convertEvents, importEvents} = useApi();
    const {updateEventsByTeamSelection, updateEventsByRecurrence} = useTeamCalendarEventUpdater();
    const [isLargeScreen, setIsLargeScreen] = React.useState(window.innerWidth >= 1024);
    const [isSmallScreen, setIsSmallScreen] = React.useState(window.innerWidth < 460);
    const [isShowAllEventInfo, setIsShowAllEventInfo] = React.useState(false);

    let queryFunction = null;
    if (canTeamEvents && teamId) {
        queryFunction = () => {
            return queryTeamEvents(range, teamId);
        };
    } else {
        queryFunction = () => {
            return queryEvents(range);
        }
    }
    const {data: events = null, isLoadingEvents, refetch: eventsRefetch} = queryFunction();
    const [isLoading, setIsLoading] = useState(true);
    const {eventColors} = useConfig();
    const { data: primaryPatientsIds = [] } = queryPrimaryPatientsIds(employeeId);

    const getStatusColor = (event) => {
        if (event.event_type_id === 'CLIENT_APPOINTMENT') {
            if (event.status === 'NOT_PERFORMED') {
                if (event.employee_ids.includes(employeeId)) {
                    return eventColors.notPerformed;
                } else {
                    return eventColors.notPerformedLight;
                }
            } else if (event.status === 'PERFORMED') {
                if (event.employee_ids.includes(employeeId)) {
                    return eventColors.performed;
                } else {
                    return eventColors.performedLight;
                }
            } else if (event.status === 'CONFIRMED') {
                return eventColors.confirmed;
            }
        }
    }

    const getUserEventColor = (event) => {
        if (event.event_type_id === 'CLIENT_APPOINTMENT') {
            if (event.employee_ids.length > 0) {
                if (event.status === 'NOT_PERFORMED') {
                    if (event.employee_ids.includes(employeeId)) {
                        return eventColors.notPerformed;
                    } else {
                        return eventColors.notPerformedLight;
                    }
                } else if (event.status === 'PERFORMED') {
                    if (event.employee_ids.includes(employeeId)) {
                        return eventColors.performed;
                    } else {
                        return eventColors.performedLight;
                    }
                } else if (event.status === 'CONFIRMED') {
                    return eventColors.confirmed;
                }
            } else {
                return eventColors.notAssigned;
            }
        } else if (event.event_type_id === 'INTERNAL') {
            return eventColors.internal;
        }
    }

    const handleCalendarLoad = () => {
        setIsLoading(false);
    };

    const onTeamSelectionChange = (newTeamSelection) => {
        setTeamSelection(newTeamSelection);
    }


    useEffect(() => {
        if (events) {
            const isTeamRelated = canTeamEvents && teamId;
            let result = updateEventsByTeamSelection(isTeamRelated, events, teamSelection, employeeId, primaryPatientsIds);
            updateEventsByRecurrence(result);
            setFilteredEvents(result);
            setIsLoading(false);
        }
    }, [teamSelection, events]);

    const handleEventsUpdated = () => {
        eventsRefetch();
    };

    const handlePrevClick = () => {
        calendarRef.current.getApi().prev();
    };

    const handleNextClick = () => {
        calendarRef.current.getApi().next();
    };

    const handleTodayClick = () => {
        calendarRef.current.getApi().today();
    };

    const handleEventModalClose = (event = null, action = 'created', close = true) => {
        if (event) {
            if (action === 'created' || action === 'updated') {
                event.allDay = event.all_day === 1;

                if (event.event_type_id === 'HOLIDAY') {
                    event.title = t('team-calendar.event-types.holiday', 'Holiday');
                } else if (event.event_type_id === 'SICK') {
                    event.title = t('team-calendar.event-types.sick', 'Sick');
                }

                let newFilteredEvents = [...filteredEvents];

                switch (event.event_type_id) {
                    case 'INTERNAL': 
                        event.stripeColor = eventColors.internal;
                        break;
                    default:
                        event.stripeColor = event.employee_colors.length > 0 && event.employee_colors[0] 
                            ? event.employee_colors[0] 
                            : eventColors.notAssigned;
                        break;
                }

                event.color = 'transparent';
                
                if (action === 'created') {
                    newFilteredEvents.push(event);
                    setFilteredEvents(newFilteredEvents);
                } else if (action === 'updated') {
                    for (let i = 0; i < newFilteredEvents.length; i++) {
                        if (newFilteredEvents[i].id === event.id) {
                            newFilteredEvents[i] = event;
                            setFilteredEvents(newFilteredEvents);
                            break;
                        }
                    }
                }
            } else if (action === 'deleted') {
                let newFilteredEvents = [...filteredEvents];
                for (let i = 0; i < newFilteredEvents.length; i++) {
                    if (newFilteredEvents[i].id === event.id) {
                        newFilteredEvents.splice(i, 1);
                        setFilteredEvents(newFilteredEvents);
                        break;
                    }
                }
            }
        }

        if (!event && action === 'deleted') {
            handleEventsUpdated();
        }

        if (close) {
            setSelectedDate(null);
            goToTeamCalendar();
        }
    }

    const handleDateChange = (dateInfo) => {
        setRange({
            start: moment(dateInfo.start).subtract(1, 'week').format('YYYY-MM-DD'),
            end: moment(dateInfo.end).add(1, 'week').format('YYYY-MM-DD')
        });
    }
    const handleDateClick = (arg) => {
        setSelectedDate(arg);
        if (arg.allDay && arg.view.type !== 'timeGridMonth') {
            goToTeamCalendarNewEvent('holiday');
        } else {
            goToTeamCalendarNewEvent('client_appointment');
        }
    }

    const handleEventClick = (arg) => {
        if (arg.event.id) {
            goToTeamCalendarEvent(arg.event.id, arg.event.startStr);
        }
    }

    const eventButtonHandler = (eventType) => {
        goToTeamCalendarNewEvent(eventType);
    }

    const gridButtonHandler = (gridType) => {
        const calendarApi = calendarRef.current.getApi();
        
        if (gridType === 'five-days') {
            setHiddenDays([0, 6]);
            setIsShowAllEventInfo(false);
            calendarApi.changeView('timeGridWeek')
        } else if (gridType === 'single-day') {
            setHiddenDays([]);
            setIsShowAllEventInfo(true);
            calendarApi.changeView('timeGridDay')
        } else if (gridType === 'week') {
            setHiddenDays([]);
            setIsShowAllEventInfo(false);
            calendarApi.changeView('timeGridWeek')
        } else if (gridType === 'month') {
            setHiddenDays([]);
            setIsShowAllEventInfo(false);
            calendarApi.changeView('dayGridMonth')
        } else if (gridType === 'list') {
            setHiddenDays([]);
            setIsShowAllEventInfo(true);
            calendarApi.changeView('listWeek')
        }

        calendarApi.today();
    }

    const downloadEventsHandler = () => {
        setIsEventsExportModalOpen(true);
    }

    const importEventsHandler = () => {
        setIsEventsImportModalOpen(true);
    }

    const handleEventsConvert = (file, onFinish) => {
        convertEvents(file, onFinish);
    }


    const handleEventsImport = (file, onFinish, onSuccess) => {
        importEvents(file, onFinish, onSuccess, eventsRefetch);
    }


    const ProgressBar = ({ progressPercentage }) => {
        return (
            <div className='h-1 w-full bg-gray-300'>
                <div
                    style={{ width: `${progressPercentage}%`}}
                    className={`h-full ${
                        progressPercentage < 70 ? 'bg-blue-600' : 'bg-green-600'}`}>
                </div>
            </div>
        );
    };

    const eventRender = (info) => {
        return (
            <div>
                <strong>mmmm {info.event.title}</strong> {/* Customize the rendering of the event title */}
                <p>mmmm {info.event.start.toLocaleString()}</p> {/* Example: Display the event start date */}
            </div>
        );
    };

    const renderEventContent = (eventInfo) => {;
        const color = getStatusColor(eventInfo.event.extendedProps);
        const isEventEmployeeListEmpty = eventInfo.event.extendedProps.employee_ids.length === 0;

        let stripeColor = eventColors.internal;

        switch (eventInfo.event.extendedProps.event_type_id) {
            case 'CLIENT_APPOINTMENT':
                stripeColor = !isEventEmployeeListEmpty ? eventInfo.event.extendedProps.stripeColor : eventColors.notAssigned;
                break;
            case 'HOLIDAY':
                stripeColor = !isEventEmployeeListEmpty ? eventInfo.event.extendedProps.stripeColor : eventColors.internal;
                break;
            case 'SICK':
                stripeColor = !isEventEmployeeListEmpty ? eventInfo.event.extendedProps.stripeColor : eventColors.internal;
                break;
            default:
                stripeColor = eventColors.internal;
                break;
        }

        let title = eventInfo.event.title;

        return (
            <div style={{
                backgroundColor: '#ffffff',
                borderRadius: '4px',
                border: '1px solid #aaaaaa',
                borderLeft: '8px solid ' + stripeColor,
                height: '100%',
                maxWidth: '100%',
                position: 'relative',
                cursor: 'pointer',
                fontSize: '12px',
                padding: '4px',
                color: '#aaa',
                overflow: 'hidden'
            }}>
                { eventInfo.event.extendedProps.event_type_id === 'CLIENT_APPOINTMENT' &&
                    <div style={{float: 'right', width: '16px', height: '16px'}}>
                        <div
                            className={'h-3 w-3 rounded-full cursor-pointer'}
                            style={{backgroundColor: color}}
                        ></div>
                    </div>
                }

                { isShowAllEventInfo 
                    ?
                    <div>{t('team-calendar.event.time', {date: eventInfo.event.start})} - {t('team-calendar.event.time', {date: eventInfo.event.end})}</div>
                    :
                    <div className="hidden sm:block">{t('team-calendar.event.time', {date: eventInfo.event.start})}</div>
                }

                { isShowAllEventInfo 
                    ?
                    <div>
                        <div className="truncate max-w-full">{title}</div>
                        { eventInfo.event.extendedProps.location &&
                            <div className="truncate max-w-full">{eventInfo.event.extendedProps.location}</div>
                        }
                    </div>
                    :
                    <div>
                        <div className="text-ellipsis overflow-hidden sm:whitespace-nowrap break-all max-w-full mt-4 sm:mt-0">
                            {title}
                        </div>
                        { eventInfo.event.extendedProps.location &&
                            <div className="hidden sm:block truncate max-w-full">{eventInfo.event.extendedProps.location}</div>
                        }
                    </div>
                }
            </div>
        );
    };

    useEffect(() => {
        const handleResize = () => {
            setIsLargeScreen(window.innerWidth >= 1024);
            setIsSmallScreen(window.innerWidth < 460);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    return <div className="relative">
        {isLoading &&
            <div className="h-1 inset-x-0 overflow-hidden absolute top-0">
                <div className="absolute inset-0 bg-blue-500 animate-pulse"></div>
                {/*<div className="absolute left-0 top-0 bg-blue-500 h-full animate-slide"></div>*/}
            </div>
        }
        <header id='team-calendar-container-header' 
            className="sticky top-0 h-20 lg:h-14 items-center justify-between border-b border-gray-200 py-2 px-2 md:px-6 bg-white flex-1 flex-col z-10">
                <div className="flex items-center justify-between">
                    <div className="flex">
                        <TeamMemberDropdown teamSelectionHandler={onTeamSelectionChange} canTeamEvents={canTeamEvents} />
                        { canExportEvents && !isSmallScreen &&
                            <button onClick={downloadEventsHandler} type="button" className="inline-flex justify-center items-center px-4 py-2 mr-2 text-sm font-medium text-gray-400 bg-white rounded-md border border-gray-300 hover:bg-gray-50 focus:outline-none text-gray-700">
                                <span className="hidden md:block text-gray-700"><Trans i18nKey="team-calendar.export">Export</Trans></span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="sm:block md:hidden w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                </svg>
                            </button>
                        }
                        { canImportEvents && !isSmallScreen &&
                            <button onClick={importEventsHandler} type="button" className="inline-flex justify-center items-center px-4 py-2 text-sm font-medium text-gray-400 bg-white rounded-md border border-gray-300 hover:bg-gray-50 focus:outline-none text-gray-700">
                                <span className="hidden md:block text-gray-700"><Trans i18nKey="team-calendar.eents-import">Import</Trans></span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="sm:block md:hidden w-5 h-5">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                </svg>
                            </button>
                        }
                    </div>
                    {isLargeScreen &&
                        <div className="flex justify-center flex-grow">
                            <p className="text-gray-700 text-base font-medium">{calendarRef?.current?.calendar?.currentData?.viewTitle}</p>
                        </div>
                    }
                    <div className="flex justify-end">
                        <TeamCalendarNavigation 
                            gridButtonHandler={gridButtonHandler} 
                            handlePrevClick={handlePrevClick} 
                            handleTodayClick={handleTodayClick} 
                            handleNextClick={handleNextClick}
                        />
                        <div className="hidden md:block mx-6 my-2 h-5 w-px bg-gray-300 flex-shrink-0"></div>
                        <div className="md:hidden lg:mx-1 lg:w-px"></div>
                        <EventDropdown eventHandler={eventButtonHandler}/>
                    </div>
                </div>
                {!isLargeScreen &&
                    <div className="flex justify-center flex-grow my-1">
                        <p className="text-gray-700 text-base font-medium">{calendarRef?.current?.calendar?.currentData?.viewTitle}</p>
                    </div>
                }
        </header>
        <div className="relative bg-white">
        <FullCalendar
            ref={calendarRef}
            eventContent={renderEventContent}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin, rrulePlugin]}
            initialView={initialView}
            dateClick={handleDateClick}
            eventClick={handleEventClick}
            allDayText={''}
            datesSet={handleDateChange}
            slotEventOverlap={false}
            headerToolbar={false}
            height={'100vh'}
            contentHeight={'auto'}
            locale={deLocale}
            events={filteredEvents}
            slotDuration={'00:15:00'}
            slotLabelInterval={'01:00:00'}
            hiddenDays={hiddenDays}
            views={{
                timeGridDay: {
                    type: 'timeGrid',
                    duration: { days: 1 },
                },
                timeGridWeek: {
                    type: 'timeGrid',
                    duration: { weeks: 1 },
                },
                listWeek: {
                    type: 'list',
                    duration: { days: 7 },
                }
            }}
            slotLabelFormat={{
                hour: 'numeric',
                minute: '2-digit',
                omitZeroMinute: false,
                meridiem: 'short'
            }}
            // eventContent={eventRender}
        />
        </div>
        <EventsExportModal isOpen={isEventsExportModalOpen} onClose={() => setIsEventsExportModalOpen(false)} />
        <ImportModal 
                isOpen={isEventsImportModalOpen}
                onClose={() => setIsEventsImportModalOpen(false)}
                title={t("employees.import-events", "Import Events")}
                handleConvert={handleEventsConvert}
                handleImport={handleEventsImport}
            />

        <Routes>
            <Route path="/new-event/:type" element={<EventModal currentUserId={userId} currentEmployeeId={employeeId} teamId={teamId} canTeamEvents={canTeamEvents} isOpen={true} event={selectedEvent} onClose={handleEventModalClose} date={selectedDate} handleEventsUpdated={handleEventsUpdated} />} />
            <Route path="/events/:id" element={<EventModal currentUserId={userId} currentEmployeeId={employeeId} teamId={teamId} canTeamEvents={canTeamEvents} isOpen={true} event={selectedEvent} onClose={handleEventModalClose} date={selectedDate} handleEventsUpdated={handleEventsUpdated} />} />
        </Routes>
    </div>
}
