import {DataGrid} from '@mui/x-data-grid';
import useApi from "../../hooks/useApi";
import {useSearchParams} from "react-router-dom";
import {Trans, useTranslation} from "react-i18next";
import QuickSearchToolbar from "./../QuickSearchToolbar";
import React, {useEffect} from "react";
import {Box, Fab, Paper} from "@mui/material";
import useRouting from "../../hooks/useRouting";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ImportModal from "../ImportModal";

const Clients = (props) => {
    const [isSmallScreen, setIsSmallScreen] = React.useState(window.innerWidth < 600);
    const [columnVisibility, setColumnVisibility] = React.useState({
        address: !isSmallScreen,
        phone: !isSmallScreen,
        employee: !isSmallScreen
    });
    const [searchParams] = useSearchParams();
    const routing = useRouting();
    const {t} = useTranslation();
    const {queryClients, exportClients, convertClients, importClients} = useApi();
    const [rows, setRows] = React.useState([]);
    const [total, setTotal] = React.useState(0);
    const [columns, setColumns] = React.useState([
        {
            field: 'full_name',
            headerName: t('clients.full-name.label', 'Name'),
            flex: 1,
            renderCell: (params) => {
                return params.row.person.last_name + ', ' + params.row.person.first_name;
            },
            editable: false,
            headerClassName: 'data-grid-header'
        },
        {
            field: 'address',
            headerName: t('clients.address.label', 'Address'),
            flex: 1,
            renderCell: (params) => {
                return params.row.person.address.street + ' ' + params.row.person.address.street_number + ', ' + params.row.person.address.zip + ' ' + params.row.person.address.city;
            },
            editable: false,
            headerClassName: 'data-grid-header'
        },
        {
            field: 'phone',
            headerName: t('clients.phone.label', 'Phone'),
            flex: 1,
            renderCell: (params) => {
                const phone = params.row.person.phone_home; // Assuming phone is an object
                const phoneNumber = phone?.phone_number ?? '';  // Phone number (with leading 0)
                let countryCode = phone?.country_code ?? '';  // Country code
                if (countryCode == 'DE') {
                    countryCode = '49';
                } else {
                    countryCode = '';
                }
                if (!phoneNumber || !countryCode) {
                    return ''; // Return empty if no phone or country code
                }
        
                // Remove leading 0 from the phone number
                const formattedPhoneNumber = phoneNumber.startsWith('0') ? phoneNumber.slice(1) : phoneNumber;
        
                // Combine country code and formatted phone number
                const fullPhoneNumber = `${countryCode}${formattedPhoneNumber}`;
        
                // Render the clickable phone number
                return (
                    <a href={`tel:+${fullPhoneNumber}`} style={{ color: 'blue', textDecoration: 'none' }}>
                        {phoneNumber} {/* Display the phone number with leading 0 */}
                    </a>
                );
            },
            editable: false,
            headerClassName: 'data-grid-header'
        },
        {
            field: 'employee',
            headerName: t('clients.employee.label', 'Employee'),
            flex: 1,
            renderCell: (params) => {
                if (params.row.employees && params.row.employees[0]) {
                    return params.row.employees[0].person.first_name + ' ' + params.row.employees[0].person.last_name;
                }
                return '';
            },
            editable: false,
            headerClassName: 'data-grid-header'
        },
    ]);
    const [searchText, setSearchText] = React.useState(searchParams.get('search') ?? '');
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: searchParams.get('pageSize') ? parseInt(searchParams.get('pageSize')) : props.paginationModel.pageSize,
        page: searchParams.get('page') ? parseInt(searchParams.get('page')) : props.paginationModel.page,
    });
    const {data: clients = null, isLoading, refetch: clientsRefetch} = queryClients(paginationModel.pageSize, paginationModel.page + 1, searchText);

    const [isImportModalOpen, setIsImportModalOpen] = React.useState(false);

    const handleRowClick = (params, event) => {
        routing.goToClient(params.id);
    }

    const handleNewClientClick = (event) => {
        routing.goToClient('new');
    }

    const handleExportClientsClick = (event) => {
        exportClients();
    }


    const handleImportClientsClick = (event) => {
        setIsImportModalOpen(true);
    }


    const handleClientsConvert = (file, onFinish) => {
        convertClients(file, onFinish);
    }


    const handleClientsImport = (file, onFinish, onSuccess) => {
        importClients(file, onFinish, onSuccess, clientsRefetch);
    }
    
    
    const getFilterParams = () => {
        let params = {};
        if (paginationModel.page !== 0)
            params['page'] = paginationModel.page;
        if (paginationModel.pageSize !== 10)
            params['pageSize'] = paginationModel.pageSize;
        if (searchText !== '')
            params['search'] = searchText;
        return params;
    };

    const pushHistory = (key = null, value = null) => {
        let params = getFilterParams();

        if (value)
            params[key] = value;
        else if (key)
            delete params[key];

        routing.pushClientsParams(params);
    };

    React.useEffect(() => {
        if (clients?.data) {
            setRows(clients.data);
        }
        if (clients?.meta.total) {
            setTotal(clients.meta.total);
        }
    }, [clients]);

    React.useEffect(() => {
        props.setPaginationModel(paginationModel);
        pushHistory();
    }, [paginationModel]);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 600);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        let newColumnVisibility = {
            ...columnVisibility,
        }
        newColumnVisibility.address = !isSmallScreen;
        newColumnVisibility.phone = !isSmallScreen;
        newColumnVisibility.employee = !isSmallScreen;
        setColumnVisibility(newColumnVisibility);
    }, [isSmallScreen]);

    const changePage = (steps) => {
        setPaginationModel({
            page: paginationModel.page + steps,
            pageSize: paginationModel.pageSize
        })
    }

    return (
        <div style={{padding: 20, height: '100%', width: '100%', minHeight: 400}}>
            <Paper elevation={0}>
                <DataGrid
                    paginationMode="server"
                    slots={{toolbar: QuickSearchToolbar}}
                    rows={rows}
                    columns={columns}
                    pagination
                    rowCount={total}
                    paginationModel={paginationModel}
                    onPaginationModelChange={setPaginationModel}
                    initialState={{
                        pagination: {paginationModel: {pageSize: 10}},
                    }}
                    columnVisibilityModel={columnVisibility}
                    onColumnVisibilityModelChange={setColumnVisibility}
                    pageSizeOptions={[10, 25, 100]}
                    autoHeight={true}
                    onRowClick={handleRowClick}
                    loading={isLoading}
                    hideFooterPagination={isSmallScreen}
                    slotProps={{
                        toolbar: {
                            value: searchText,
                            onChange: (event) => setSearchText(event.target.value),
                            onBlur: (event) => pushHistory('search', event.target.value),
                            clearSearch: () => {
                                setSearchText('');
                                pushHistory('search', '');
                            },
                            onClickCreate: handleNewClientClick,
                            createButtonLabel: <Trans i18nKey="clients.create-title">New Client</Trans>,
                            createPermissions: ["clients.create", "clients"],
                            onClickExport: handleExportClientsClick,
                            exportButtonLabel: t("clients.export-clients", "Export Clients"),
                            exportPermissions: ["clients.export", "clients"],
                            onClickImport: handleImportClientsClick,
                            importButtonLabel: t("clients.import-clients", "Import Clients"),
                            importPermissions: ["clients.import", "clients"]
                        },
                    }}
                    sx={{
                        '&.MuiDataGrid-root': {
                            border: '0 !important',
                        },
                        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
                            outline: 'none !important',
                        },
                        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, &.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                            outline: 'none !important',
                        },
                        '& > .MuiDataGrid-columnSeparator': {
                            visibility: 'hidden',
                        },
                        '& .MuiDataGrid-columnHeaderTitle': {
                            fontWeight: 'bold'
                        },
                        '& .data-grid-header': {
                            '& > .MuiDataGrid-columnSeparator': {
                                visibility: 'hidden',
                            },
                        },
                        '& .data-grid-header:first-of-type': {
                            paddingLeft: 4
                        },
                        '& .MuiDataGrid-cell:first-of-type': {
                            paddingLeft: 4
                        },
                        '& .data-grid-header:last-of-type': {
                            paddingRight: 4
                        },
                        '& .MuiDataGrid-cell:last-of-type': {
                            paddingRight: 4
                        },
                    }}
                />
            </Paper>
            {isSmallScreen &&
                <Box
                    sx={{
                    position: 'fixed',
                    bottom: 16,
                    '@media (min-width: 600px)': {
                        display: 'none',
                    },
                }}
                    >
                    <Fab
                        disabled={paginationModel.page === 0}
                        onClick={() => changePage(-1)}
                        size="small"
                        color="grey"
                        aria-label="left">
                        <ChevronLeftIcon />
                    </Fab>
                    <Fab
                        disabled={paginationModel.page >= clients?.meta.last_page - 1}
                        onClick={() => changePage(1)}
                        sx={{
                            marginLeft: 1
                        }}
                        size="small"
                        color="grey"
                        aria-label="right">
                        <ChevronRightIcon />
                    </Fab>
                </Box>
            }
            <ImportModal 
                isOpen={isImportModalOpen}
                onClose={() => setIsImportModalOpen(false)}
                title={t("clients.import-clients", "Import Clients")}
                handleConvert={handleClientsConvert}
                handleImport={handleClientsImport}
            />
        </div>
    );
}

export default Clients;
