import useApi from "../../hooks/useApi";
import {useNavigate, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import React from "react";
import {Paper} from "@mui/material";
import FormTable from ".././forms/FormTable";


const ClientContacts = (props) => {
    const isSmallScreen = window.innerWidth < 600;
    const [columnVisibility, setColumnVisibility] = React.useState({
        address: !isSmallScreen,
        email: !isSmallScreen,
        phone: !isSmallScreen,
    });
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {queryClientContacts} = useApi();

    const [columns, setColumns] = React.useState([
        {
            field: 'name',
            headerName: 'Name',
            flex: 1,
            editable: false,
            headerClassName: 'data-grid-header',
            renderCell: (params) => {
                const fullName = params.row.person.last_name ? params.row.person.first_name +' '+ params.row.person.last_name: '';
                return <b>{fullName}</b>;
            },
        },

        {
            field: 'type',
            headerName: t('clients.contacts.contact-type','Contact Type'),
            flex: 1,
            editable: false,
            headerClassName: 'data-grid-header',
            renderCell: (params) => {
                const type = params.row.type ? t('clients.contacts.type.' + params.row.type): '';
                return type;
            },
        },
        {
            field: 'email',
            headerName: t('clients.contacts.email-address','E-mail Address'),
            flex: 1,
            editable: false,
            headerClassName: 'data-grid-header',
            renderCell: (params) => {
                const email = params.row.person.email ?? '';
                return email;
            },
        },
        {
            field: 'phone',
            headerName: t('clients.contacts.phone', 'Phone'),
            flex: 1,
            editable: false,
            headerClassName: 'data-grid-header',
            renderCell: (params) => {
                const phoneNumber = params.row.phone?  params.row.phone.phone_number  :'';
                return phoneNumber;
            },
        },
        {
            field: 'address',
            headerName: t('clients.contacts.address', 'Address'),
            flex: 1,
            renderCell: (params) => {
                const address = params.row.person.address;
                let street = address?.street ?? '';
                let street_number = address?.street_number ?? '';
                let zip = address?.zip ?? '';
                let city = address?.city ?? '';
                 return (street && street_number && zip && city) ? street + ' ' + street_number + ', ' + zip + ' ' + city : "";
             },
            editable: false,
            headerClassName: 'data-grid-header'
        },
    ]);
    const [sortModel, setSortModel] = React.useState(searchParams.get('sortField') && searchParams.get('sortOrder') ? [{
        field: searchParams.get('sortField'),
        sort: searchParams.get('sortOrder')
    }] : []);
    const [searchText, setSearchText] = React.useState(searchParams.get('search') ?? '');
    const [paginationModel, setPaginationModel] = React.useState({
        pageSize: searchParams.get('pageSize') ? parseInt(searchParams.get('pageSize')) : props.paginationModel.pageSize,
        page: searchParams.get('page') ? parseInt(searchParams.get('page')) : props.paginationModel.page,
    });
    const {data: clientContacts = null, isLoading} = queryClientContacts(
        props.client.id ,
        paginationModel.pageSize,
        paginationModel.page + 1,
        sortModel.length > 0 ? sortModel[0].field : null,
        sortModel.length > 0 ? sortModel[0].sort : null,
        searchText);

    const handleRowClick = (params) => {
        const contactId = params.row.id;
        navigate(`${contactId}`);
    }
    const handleNewClientContactClick = (event) => {
        const contactId = 'new';
        navigate(`${contactId}`);
    }

    const handleScreenSizeChange = (isSmallScreen) => {
        let newColumnVisibility = {
            ...columnVisibility,
        }
        newColumnVisibility.address = !isSmallScreen;
        newColumnVisibility.email = !isSmallScreen;
        newColumnVisibility.phone = !isSmallScreen;
        setColumnVisibility(newColumnVisibility);
    }

    return (
        <Paper elevation={0} sx={{marginBottom: 2, padding: 2, maxWidth: 1024}}>
            <FormTable
                createButtonLabel={t("clients.contacts.new-contact", "New Contact")}
                columns={columns}
                data={clientContacts}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                onSearchTextChange={setSearchText}
                onRowClick={handleRowClick}
                onNewClick={handleNewClientContactClick}
                sortModel={sortModel}
                setSortModel={setSortModel}
                isLoading={isLoading}
                onScreenSizeChange={handleScreenSizeChange}
                columnVisibility={columnVisibility}
                setColumnVisibility={setColumnVisibility}
                createPermissions={['clients', 'client', 'client.contacts', 'client.contacts.create']}
            />
        </Paper>
    );
}

export default ClientContacts;
