import {
    Box,
    Button,
    FormControl,
    Grid,
    InputAdornment,
    MenuItem,
    OutlinedInput,
    Paper,
    Select,
    TextField, Typography
} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import useApi from "../../hooks/useApi";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import moment from "moment";
import {DesktopDatePicker} from "@mui/x-date-pickers";
import {DateTime} from "luxon";
import useRouting from "../../hooks/useRouting";
import {useTheme} from "@mui/material/styles";
import FormTextField from "../forms/FormTextField";
import FormAutocomplete from "../forms/FormAutocomplete";
import FormDateField from "../forms/FormDateField";
import ClientInstitutionsField from "./ClientInstitutionsField";
import ClientCareDegreesField from "./ClientCareDegreesField";
import FormSection from "../forms/FormSection";

const ClientAdministrativeData = ({
                                      editable,
                                      onSave,
                                      onCancel,
                                      setIsValid,
                                      showValidation,
                                      clientAdministrativeData,
                                      setClientAdministrativeData,
                                      showButtons = false
                                  }) => {

    const {t} = useTranslation();
    const theme = useTheme();
    const {queryAllEmployees, queryInstitutions, queryPhyisicians} = useApi();
    const {data: employees} = queryAllEmployees();
    const {data: institutions = null} = queryInstitutions(2000, 0);
    const {data: physicians = null} = queryPhyisicians();

    const [disabled, setDisabled] = useState(false);
    const [errClientNumber, setErrClientNumber] = useState(false);
    const [errEmployee, setErrEmployee] = useState(false);
    const [errSecEmployee, setErrSecEmployee] = useState(false);
    const [errClientSince, setErrClientSince] = useState(false);
    const [errNote, setErrNote] = useState(false);
    const [employeesWithoutPrimary, setEmployeesWithoutPrimary] = useState([]);

    const handleClientNumberChange = (event) => {
        setClientAdministrativeData({...clientAdministrativeData, clientNumber: event.target.value});
    }

    const handleNoteChange = (event) => {
        setClientAdministrativeData({...clientAdministrativeData, note: event.target.value});
    }

    const validate = () => {
        let valid = true;

        if (clientAdministrativeData.clientNumber === '') {
            setErrClientNumber(true);
            valid = false;
        } else {
            setErrClientNumber(false);
        }

        if (!clientAdministrativeData.contractStart) {
            setErrClientSince(true);
            valid = false;
        } else {
            setErrClientSince(false);
        }
       if ((clientAdministrativeData.employee === clientAdministrativeData.secondaryEmployee) && clientAdministrativeData.employee != null) {
            setErrSecEmployee(true);
            valid = false;
        } else {
            setErrSecEmployee(false);
        }
        if (clientAdministrativeData.note.length > 255) {
            setErrNote(true);
            valid = false;
        } else {
            setErrNote(false);
        }

        setIsValid(valid);
        return valid;
    }

    useEffect(() => {
        validate();
    }, [showValidation, clientAdministrativeData]);

    useEffect(() => {
        if (!employees){
            return;
        }
        let filteredEmployees = employees;
        if (clientAdministrativeData.employee) {
            filteredEmployees = employees.filter(employee => employee.id !== clientAdministrativeData.employee.id);
        } 
        setEmployeesWithoutPrimary(filteredEmployees);
    }, [clientAdministrativeData, employees]);

    return (
        <FormSection
            editable={editable}
            showButtons={showButtons}
            title={<Trans i18nKey="clients.administrative-data">Administrative Data</Trans>}
            onSave={onSave}
            onCancel={onCancel}
        >
            <FormTextField
                required={true}
                value={clientAdministrativeData.clientNumber}
                editable={editable}
                onChange={handleClientNumberChange}
                i18nKeyLabel="clients.client-number.label"
                i18nKeyPlaceholder="clients.client-number.placeholder"
                valueText={clientAdministrativeData.clientNumber}
                disabled={disabled}
                showValidation={showValidation}
                error={errClientNumber}/>


            <FormAutocomplete
                value={clientAdministrativeData.employee}
                editable={editable}
                onChange={(newValue) => {
                    setClientAdministrativeData({...clientAdministrativeData, employee: newValue});
                }}
                i18nKeyLabel="clients.employee.label"
                i18nKeyPlaceholder="clients.employee.placeholder"
                options={employees}
                getOptionLabel={(option) => option.person.last_name + ', ' + option.person.first_name}
                valueText={clientAdministrativeData.employee ? clientAdministrativeData.employee.person.last_name + ', ' + clientAdministrativeData.employee.person.first_name : null}
                disabled={disabled}
                showValidation={showValidation}
                error={errEmployee}
            />

            <FormAutocomplete
                value={clientAdministrativeData.secondaryEmployee}
                editable={editable}
                onChange={(newValue) => {
                    setClientAdministrativeData({...clientAdministrativeData, secondaryEmployee: newValue});
                }}
                i18nKeyLabel="clients.secondary-employee.label"
                i18nKeyPlaceholder="clients.secondary-employee.placeholder"
                options={employeesWithoutPrimary}
                getOptionLabel={(option) => option.person.last_name + ', ' + option.person.first_name}
                valueText={clientAdministrativeData.secondaryEmployee ? clientAdministrativeData.secondaryEmployee.person.last_name + ', ' + clientAdministrativeData.secondaryEmployee.person.first_name : null}
                disabled={disabled}
                showValidation={showValidation}
                error={errSecEmployee}
                i18nErrorKey={'clients.secondary-employee.error'}
                i18nErrorValue={'The primary and secondary caregiver need to differ'}
            />

            <FormDateField
                required={true}
                value={clientAdministrativeData.contractStart}
                editable={editable}
                onChange={(newValue) => {
                    setClientAdministrativeData({...clientAdministrativeData, contractStart: newValue});
                }}
                i18nKeyLabel="clients.contract-start.label"
                i18nKeyPlaceholder="clients.contract-start.placeholder"
                i18nKeyDateFormat="clients.contract-start.date"
                valueText={clientAdministrativeData.contractStart}
                disabled={disabled}
                showValidation={showValidation}
                error={errClientSince}
            />

            <FormDateField
                value={clientAdministrativeData.contractEnd}
                editable={editable}
                onChange={(newValue) => {
                    setClientAdministrativeData({...clientAdministrativeData, contractEnd: newValue});
                }}
                i18nKeyLabel="clients.contract-end.label"
                i18nKeyPlaceholder="clients.contract-end.placeholder"
                i18nKeyDateFormat="clients.contract-end.date"
                valueText={clientAdministrativeData.contractEnd}
                disabled={disabled}
                showValidation={showValidation}
            />

            <ClientInstitutionsField
                values={clientAdministrativeData.healthInsurances}
                onChange={(newValues) => {
                    setClientAdministrativeData({...clientAdministrativeData, healthInsurances: newValues});
                }}
                i18nKeyLabel={"clients.health-insurances.label"}
                i18nKeyAddButton={"clients.health-insurances.add"}
                i18nKeyDateFormat={"clients.health-insurances.date"}
                editable={editable}
                institutions={institutions?.data.filter((institution) => institution.type === 'HEALTH_INSURANCE' || institution.type === 'PRIVATE_HEALTH_INSURANCE')}
            />

            <ClientInstitutionsField
                values={clientAdministrativeData.careInsurances}
                onChange={(newValues) => {
                    setClientAdministrativeData({...clientAdministrativeData, careInsurances: newValues});
                }}
                i18nKeyLabel={"clients.care-insurances.label"}
                i18nKeyAddButton={"clients.care-insurances.add"}
                i18nKeyDateFormat={"clients.care-insurances.date"}
                editable={editable}
                institutions={institutions?.data.filter((institution) => institution.type === 'CARE_INSURANCE' || institution.type === 'PRIVATE_CARE_INSURANCE')}
            />

            <ClientCareDegreesField
                values={clientAdministrativeData.careDegrees}
                onChange={(newValues) => {
                    setClientAdministrativeData({...clientAdministrativeData, careDegrees: newValues});
                }}
                i18nKeyLabel={"clients.care-degrees.label"}
                i18nKeyAddButton={"clients.care-degrees.add"}
                i18nKeyDateFormat={"clients.care-degrees.date"}
                editable={editable}
            />

            <FormAutocomplete
                multiple={true}
                value={clientAdministrativeData.disabilityCodes}
                editable={editable}
                onChange={(newValue) => {
                    setClientAdministrativeData({...clientAdministrativeData, disabilityCodes: newValue});
                }}
                i18nKeyLabel="clients.disability-codes.label"
                i18nKeyPlaceholder="clients.disability-codes.placeholder"
                options={[{id: 'G', key: 'G'}, {id: 'aG', key: 'aG'}, {id: 'h', key: 'h'}, {id: 'bl', key: 'bl'}, {id: 'gl', key: 'gl'}, {id: 'tbl', key: 'tbl'}, {id: 'B', key: 'B'}, {id: 'RF', key: 'RF'}, {id: 'eb', key: 'eb'}]}
                getOptionLabel={(option) => t('clients.disability-codes.codes.' + option.key, option.key)}
                valueText={clientAdministrativeData.disabilityCodes.length > 0 ? clientAdministrativeData.disabilityCodes.map((code) => t('clients.disability-codes.codes.' + code.key, code.key)).join(', ') : '-'}
                disabled={disabled}
                showValidation={showValidation}
                error={errEmployee}
            />

            <FormTextField
                value={clientAdministrativeData.aidGrantPercent}
                editable={editable}
                onChange={(event) => {
                    setClientAdministrativeData({...clientAdministrativeData, aidGrantPercent: event.target.value});
                }}
                i18nKeyLabel="clients.aid-grant-percent.label"
                i18nKeyPlaceholder="clients.aid-grant-percent.placeholder"
                valueText={clientAdministrativeData.aidGrantPercent}
                disabled={disabled}
                showValidation={showValidation}
            />

            <FormAutocomplete
                value={clientAdministrativeData.physician}
                editable={editable}
                onChange={(newValue) => {
                    setClientAdministrativeData({...clientAdministrativeData, physician: newValue});
                }}
                i18nKeyLabel="clients.physician.label"
                i18nKeyPlaceholder="clients.physician.placeholder"
                options={physicians}
                getOptionLabel={(option) => option.person?.last_name + ', ' + option.person?.first_name}
                valueText={clientAdministrativeData.physician ? clientAdministrativeData.physician.person?.last_name + ', ' + clientAdministrativeData.physician.person?.first_name : null}
                disabled={disabled}
                showValidation={showValidation}
                error={errEmployee}
            />

            <FormAutocomplete
                multiple={true}
                value={clientAdministrativeData.livingSituation}
                editable={editable}
                onChange={(newValue) => {
                    setClientAdministrativeData({...clientAdministrativeData, livingSituation: newValue});
                }}
                i18nKeyLabel="clients.living-situation.label"
                i18nKeyPlaceholder="clients.living-situation.placeholder"
                options={[{id: 'alone', key: 'alone'}, {id: 'with_relatives', key: 'with_relatives'}, {id: 'property_owner', key: 'property_owner'}, {id: 'renting', key: 'renting'}, {id: 'assisted_living', key: 'assisted_living'}, {id: 'other', key: 'other'}]}
                getOptionLabel={(option) => t('clients.living-situation.codes.' + option.key, option.key)}
                valueText={clientAdministrativeData.livingSituation.map((livingSituation) => t('clients.living-situation.codes.' + livingSituation.key, livingSituation.key)).join(', ')}
                disabled={disabled}
                showValidation={showValidation}
            />

            <FormTextField
                value={clientAdministrativeData.note}
                editable={editable}
                onChange={handleNoteChange}
                i18nKeyLabel="clients.note.label"
                i18nKeyPlaceholder="clients.note.placeholder"
                valueText={clientAdministrativeData.note}
                disabled={disabled}
                showValidation={showValidation}
                error={errNote}
                i18nErrorKey={'clients.note.error'}
                i18nErrorValue={'the text is too long for this field'}
            />

        </FormSection>
    )
}

export default ClientAdministrativeData;
