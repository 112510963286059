import {Grid, TextField, Typography} from "@mui/material";
import {Trans, useTranslation} from "react-i18next";
import {useTheme} from "@mui/material/styles";
import useConfig from "../../hooks/useConfig";

const FormTextField = ({i18nKeyLabel,
    i18nKeyPlaceholder,
    valueText,
    editable,
    value,
    onChange,
    disabled,
    error, 
    i18nErrorKey,
    i18nErrorValue, 
    showValidation,
    required,
    multiline = false}) => {
    const theme = useTheme();
    const {t} = useTranslation();

    const {gridItemSxLabel, gridItemSxValue, gridItemSxValueEditable, gridItemSxError} = useConfig();

    return (
        <>
            <Grid item xs={12} sm={4} sx={gridItemSxLabel}>
                <Trans i18nKey={i18nKeyLabel}>{i18nKeyLabel}</Trans>
                {editable && required && <Typography component='span' sx={{color: theme.palette.error.main}}>*</Typography>}
            </Grid>
            <Grid item xs={12} sm={8} sx={editable ? gridItemSxValueEditable : gridItemSxValue}>
                {editable ?
                    <TextField
                        disabled={disabled}
                        multiline={multiline}
                        rows={multiline ? 4 : 1}
                        fullWidth
                        hiddenLabel
                        size="small"
                        variant="outlined"
                        value={value}
                        placeholder={t(i18nKeyPlaceholder, i18nKeyPlaceholder)}
                        InputProps={{style: {fontSize: 14}}}
                        sx={{
                            fontSize: 14,
                            backgroundColor: '#fff',
                            '& textarea.MuiInputBase-input:focus': {
                                boxShadow: 'none !important',
                                outline: '0 !important',
                                outlineOffset: '0 !important',
                                border: 'none !important'
                        }
                        }}
                        onChange={onChange}
                        error={showValidation && error}
                    />
                    : valueText
                }
            </Grid>
            {error && (
                <>
                <Grid item xs={12} sm={4} />
                <Grid item xs={12} sm={8} sx={gridItemSxError}>
                <p className="text-sm text-red-600">
                    <Trans i18nKey={i18nErrorKey}>
                            {i18nErrorValue}
                    </Trans>
                </p>
                </Grid>
            </>
            )}
        </>
    );
}

export default FormTextField;
